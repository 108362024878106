import React from 'react';
import {
  ReferentType,
  ReferentTypeLabels,
  Distributor,
  DistributorLabels
} from '../../common/types';

export function highlightFile(path: string) {
  const pathItems = path.split('/');
  if (pathItems.length < 2) return path;
  const filename = pathItems.pop();
  return (
    <div className="text-sm font-light break-all">
      <div>
        {pathItems.join('/') + '/'}
        <span className="font-bold">{filename}</span>
      </div>
    </div>
  );
}

export function formatDistributor(distributor: Distributor | ''): string {
  return distributor ? DistributorLabels[distributor] : '';
}

export function formatReferentType(referentType: ReferentType | '' | undefined): string {
  return referentType ? ReferentTypeLabels[referentType] : '';
}
