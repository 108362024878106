export const API_GATEWAY_URL = import.meta.env.VITE_API_GATEWAY_URL;

export const VITE_APPSYNC_API_URL = import.meta.env.VITE_APPSYNC_API_URL;

export const WORKFLOW_API_URL = import.meta.env.VITE_WORKFLOW_API_URL;

export const NODE_ENV = import.meta.env.VITE_NODE_ENV;

export const CONTENT_PORTAL_URL = import.meta.env.VITE_CONTENT_PORTAL_URL;

export const REPOSITORY_API_URL = import.meta.env.VITE_CONTENT_REPOSITORY_API_URL;
