import React, { ReactNode } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { twMerge } from 'tailwind-merge';

/*
Reference:
This component accept the following props:
https://www.radix-ui.com/primitives/docs/components/tooltip

How to use it:
 <Tooltip content="Tooltip content">
    <button>Tooltip trigger</button>
  </Tooltip>
  or
  <Tooltip
    arrowClassName="fill-black"
    className="bg-black text-white"
    side="left"
    content="hello this is a tooltip">
    show me
  </Tooltip>
*/

type TooltipContentPropsWithoutContent = Omit<TooltipPrimitive.TooltipContentProps, 'content'>;

export const Tooltip = ({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  arrowClassName,
  asChild,
  ...props
}: TooltipPrimitive.TooltipProps &
  TooltipContentPropsWithoutContent & {
    content: ReactNode;
    arrowClassName?: string;
    asChild?: boolean;
  }) => {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
        <TooltipPrimitive.Trigger asChild={asChild} type={'button'}>
          {children}
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            {...props}
            className={twMerge([
              'bg-white p-3 rounded-lg drop-shadow text-sm text-gray-700',
              props.className
            ])}
            sideOffset={5}>
            {content}
            <TooltipPrimitive.Arrow className={twMerge(['fill-white relative', arrowClassName])} />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};
