import { createSlice } from '@reduxjs/toolkit';
import { EncodeSystem, Organization, OrganizationClient } from './types';

import { Meta } from '../../common/types';

const initialState: {
  results: Organization[];
  meta: Meta;
  selected: Organization | undefined;
  clients: OrganizationClient[];
  encodeSystems: EncodeSystem[];
  selectedClient: OrganizationClient | undefined;
} = {
  meta: {
    page: 1,
    pageSize: 30,
    total: 0
  },
  results: [],
  selected: undefined,
  selectedClient: undefined,
  clients: [],
  encodeSystems: []
};

export const organizationsSlice = createSlice({
  name: 'organizationsSlice',
  initialState,
  reducers: {
    setOrganizations: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    removeOrganization: (state, action) => {
      return {
        ...state,
        meta: {
          ...state.meta,
          total: state.meta?.total - 1
        },
        results: state.results.filter((item: Organization) => item.orgId !== action.payload)
      };
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
      if (state.selected) {
        state.clients = action.payload.clients;
      }
    },
    setSelectedClient: (state, action) => {
      state.selectedClient = action.payload;
      if (state.selectedClient) {
        state.encodeSystems = action.payload.encodeSystems;
      }
    },
    setEncodeSystems: (state, action) => {
      return {
        ...state,
        encodeSystems: action.payload
      };
    },
    setClients: (state, action) => {
      return {
        ...state,
        clients: action.payload
      };
    },
    addClient: (state, action) => {
      return {
        ...state,
        clients: [...state.clients, action.payload]
      };
    },
    updateClient: (state, action) => {
      return {
        ...state,
        clients: state.clients.map((item: OrganizationClient) => {
          if (item.clientId === action.payload.clientId) {
            return action.payload;
          } else {
            return item;
          }
        })
      };
    },
    deleteClient: (state, action) => {
      return {
        ...state,
        clients: state.clients.filter(
          (item: OrganizationClient) => item.clientId !== action.payload
        )
      };
    },
    addEncodeSystem: (state, action) => {
      return {
        ...state,
        encodeSystems: [...(state.encodeSystems || []), action.payload]
      };
    },
    deleteEncodeSystem: (state, action) => {
      return {
        ...state,
        encodeSystems: state.encodeSystems.filter(
          (item: EncodeSystem) => item.ifeSystem !== action.payload
        )
      };
    },
    clearEncodeSystems: (state) => {
      return {
        ...state,
        encodeSystems: []
      };
    }
  }
});

export const {
  setOrganizations,
  removeOrganization,
  setSelected,
  setClients,
  addClient,
  deleteClient,
  addEncodeSystem,
  deleteEncodeSystem,
  clearEncodeSystems,
  updateClient,
  setSelectedClient,
  setEncodeSystems
} = organizationsSlice.actions;

export default organizationsSlice;
