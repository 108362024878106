import React from 'react';
import WarnerBrosLogo from '../../assets/warner-bros-logo.svg?react';
import HboLogo from '../../assets/hbo-logo.svg?react';
import DisneyLogo from '../../assets/disney-logo.svg?react';
import TurnerLogo from '../../assets/turner-logo.svg?react';
import UniversalLogo from '../../assets/universal-logo.svg?react';
import AdultSwimLogo from '../../assets/adult-swim-logo.svg?react';
import CartoonNetworkLogo from '../../assets/cartoon-network-logo.svg?react';
import SonyLogo from '../../assets/sony-logo.svg?react';

interface DistributorLogoProps {
  distributor: string;
  height?: string;
}

export default function DistributorLogo(props: DistributorLogoProps) {
  const height = props.height || '5rem';
  switch (props.distributor) {
    case 'WARNER_BROS':
      return <WarnerBrosLogo height={height} />;
    case 'HBO':
      return <HboLogo height={height} />;
    case 'DISNEY':
      return <DisneyLogo height={height} />;
    case 'TURNER':
      return <TurnerLogo height={resizeCss(height, 3 / 5)} />;
    case 'UNIVERSAL':
      return <UniversalLogo height={height} />;
    case 'ADULT_SWIM':
      return <AdultSwimLogo height={height} />;
    case 'CARTOON_NETWORK':
      return <CartoonNetworkLogo height={height} />;
    case 'SONY':
      return <SonyLogo height={height} />;
    default:
      return <>{props.distributor}</>;
  }
}

function resizeCss(val: string, ratio: number): string {
  // Extract the numeric value and unit using a regular expression
  const match = val.match(/^(\d+(?:\.\d+)?)(rem|px)?$/);

  if (!match) {
    throw new Error('Invalid CSS value. Must be a number optionally followed by "rem" or "px".');
  }

  const numericValue = parseFloat(match[1]); // Extracted numeric part
  const unit = match[2] || 'rem'; // Extracted unit or use rem as default
  const newValue = numericValue * ratio; // Calculate the resized value

  return `${newValue}${unit}`;
}
