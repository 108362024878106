import { Asset, AssetType, MediaInfoAudio, MediaInfoSubtitle, MediaInfoVideo } from './types';
/**
 *  Sorts assets first by type, then by language <br/>
 * Types are sorted in the following order: VIDEO, AUDIO, SUBTITLE, CLOSED_CAPTION, UNKNOWN <br/>
 * Languages are sorted alphabetically
 **/
export function sortAssetByFileTypeThenByLanguage(
  a: { type: string; language: string },
  b: { type: string; language: string }
) {
  // Compare types
  if (AssetTypeRanking[a.type as AssetType] < AssetTypeRanking[b.type as AssetType]) return -1;
  if (AssetTypeRanking[a.type as AssetType] > AssetTypeRanking[b.type as AssetType]) return 1;

  // If types are the same, compare languages
  if (a.language < b.language) return -1;
  if (a.language > b.language) return 1;
  return 0;
}

const AssetTypeRanking: Record<AssetType, number> = {
  VIDEO: 0,
  AUDIO: 1,
  SUBTITLE: 2,
  CLOSED_CAPTION: 3,
  UNKNOWN: 4
};

export function getAssetMediaInfo(
  asset?: Asset
): MediaInfoVideo | MediaInfoAudio | MediaInfoSubtitle | undefined {
  if (!asset) return undefined;
  if (asset.type === 'VIDEO') {
    return asset.mediainfo?.map((info) => JSON.parse(info)) as MediaInfoVideo;
  } else if (asset.type === 'AUDIO') {
    return asset.mediainfo?.map((info) => JSON.parse(info)) as MediaInfoAudio;
  } else if (asset.type === 'SUBTITLE') {
    return asset.mediainfo?.map((info) => JSON.parse(info)) as MediaInfoSubtitle;
  }
  return undefined;
}
