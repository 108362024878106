import { graphql } from 'gql.tada';

export const getUser = graphql(`
  query getUser {
    getCurrentUser {
      _id
      username
      name
      email
      emailHash
      enabled
      organizations
      groups
      createdAt
      modifiedAt
    }
  }
`);

export const getUsers = graphql(`
  query getUsers(
    $page: Int = 1
    $pageSize: Int = 30
    $sortOrder: SortOrder = null
    $sortField: String = "_id"
  ) {
    listUsers(page: $page, pageSize: $pageSize, sortOrder: $sortOrder, sortField: $sortField) {
      results {
        _id
        createdAt
        email
        emailHash
        modifiedAt
        name
        enabled
        organizations
        groups
        username
      }
      meta {
        page
        pageSize
        total
      }
    }
  }
`);

export const listUserOrganizations = graphql(`
  query listUserOrganizations {
    listUserOrganizations {
      _id
      name
      orgId
      orderTypes
      sourceCatalogs
      clients {
        clientId
        name
        territory
        encodeSystems {
          ifeFormat
          ifeSystem
          poDestination
          vtkTemplate
        }
        orderGroupYearMonthRequired
      }
      mezzaninePoDestinations
      createdAt
      modifiedAt
    }
  }
`);
