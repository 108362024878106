import { createSlice } from '@reduxjs/toolkit';
import { CatalogTitle, Meta } from './types';

export interface CatalogSliceType {
  results: CatalogTitle[];
  meta: Meta | null;
}

const initialState: CatalogSliceType = {
  results: [],
  meta: null
};

export const catalogSlice = createSlice({
  name: 'catalogSlice',
  initialState,
  reducers: {
    setSearchTitles: (state, action) => {
      return {
        ...action.payload
      };
    }
  }
});

export const { setSearchTitles } = catalogSlice.actions;

export default catalogSlice;
