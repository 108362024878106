import { createSlice } from '@reduxjs/toolkit';
import { Order } from './types';
import { Meta } from '../../common/types';
import { createUpdatedOrder } from './utils';

const initialState: { results: Order[]; meta: Meta | null; currentOrder?: Order } = {
  meta: {
    page: 1,
    pageSize: 25,
    total: 1
  },
  results: [],
  currentOrder: undefined
};

export const ordersSlice = createSlice({
  name: 'ordersSlice',
  initialState,
  reducers: {
    appendOrder: (state, action) => {
      return {
        ...state,
        results: [...state.results, action.payload]
      };
    },
    setOrders: (state, action) => {
      return {
        ...action.payload
      };
    },
    setOrder: (state, action) => {
      return {
        ...state,
        currentOrder: { ...action.payload } as Order
      };
    },
    updateOrderDeliveryStatus: (state, action) => {
      state.results = state.results.map((item: Order) => {
        if (action.payload._id === item._id) {
          item.deliveryStatus = action.payload.status;
        }
        return item;
      });
    },
    updateOrderStatuses: (state, action) => {
      state.results = state.results.map((item: Order) =>
        action.payload._id === item._id ? createUpdatedOrder(action.payload, item) : item
      );
    }
  }
});

export const { setOrders, setOrder, updateOrderDeliveryStatus, updateOrderStatuses, appendOrder } =
  ordersSlice.actions;

export default ordersSlice;
