import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { EditVersion, Distributor } from '../../common/types';
import { AddEidrEpisode, AddEidrSeason } from '../eidr/types';
import { AnalyzeFile, SourceCatalog, TitleCandidate } from './types';

interface IngestState {
  paths: string[];
  detectedFiles: AnalyzeFile[];
  candidates: TitleCandidate[];
  selectedCandidate: TitleCandidate;
  distributor: Distributor | '';
  editVersion: EditVersion | '';
  preselectedMpmNumber: string;
  sourceCatalog: SourceCatalog | '';
  addEidrSeason: AddEidrSeason | '';
  addEidrEpisode: AddEidrEpisode | '';
}

export const initialSelectedCandidate: TitleCandidate = {
  sourceCatalog: '',
  mpmNumber: '',
  eidrId: '',
  titleName: '',
  referentType: '',
  seasonNumber: '',
  episodeNumber: '',
  isEpisode: false,
  seriesRepositoryItemId: '',
  seriesFolderLocation: '',
  seriesEidrId: '',
  seriesName: '',
  seriesReleaseDate: '',
  distributor: '',
  editVersion: ''
};

const initialState: IngestState = {
  paths: [],
  detectedFiles: [],
  candidates: [],
  selectedCandidate: initialSelectedCandidate,
  distributor: '',
  editVersion: '',
  preselectedMpmNumber: '',
  sourceCatalog: '',
  addEidrSeason: '',
  addEidrEpisode: ''
};

export const ingestSlice = createSlice({
  name: 'ingestSlice',
  initialState,
  reducers: {
    clearAll: (state) => {
      state.paths = [];
      state.selectedCandidate = initialSelectedCandidate;
      state.addEidrSeason = '';
      state.addEidrEpisode = '';
    },
    clearContentMetadata: (state) => {
      state.selectedCandidate = initialSelectedCandidate;
      state.addEidrSeason = '';
      state.addEidrEpisode = '';
    },
    setPaths: (state, action: PayloadAction<string[]>) => {
      state.paths = action.payload;
    },
    setDetectedFiles: (state, action: PayloadAction<AnalyzeFile[]>) => {
      state.detectedFiles = action.payload;
    },
    setSelectedCandidate: (state, action: PayloadAction<TitleCandidate>) => {
      state.selectedCandidate = action.payload;
    },
    setDistributor: (state, action: PayloadAction<Distributor>) => {
      state.distributor = action.payload;
    },
    setEditVersion: (state, action: PayloadAction<EditVersion>) => {
      state.editVersion = action.payload;
    },
    setPreselectedMpmNumber: (state, action: PayloadAction<string>) => {
      state.preselectedMpmNumber = action.payload;
    },
    setSourceCatalog: (state, action: PayloadAction<SourceCatalog>) => {
      state.sourceCatalog = action.payload;
    },
    setMissingSeason: (state, action: PayloadAction<AddEidrSeason>) => {
      state.addEidrSeason = action.payload;
    },
    setMissingEpisode: (state, action: PayloadAction<AddEidrEpisode>) => {
      state.addEidrEpisode = action.payload;
    },
    setCandidates: (state, action: PayloadAction<TitleCandidate[]>) => {
      state.candidates = action.payload;
    }
  }
});

export const {
  clearAll,
  clearContentMetadata,
  setPaths,
  setDetectedFiles,
  setSelectedCandidate,
  setDistributor,
  setEditVersion,
  setPreselectedMpmNumber,
  setSourceCatalog,
  setMissingSeason,
  setMissingEpisode,
  setCandidates
} = ingestSlice.actions;

export default ingestSlice;

export const selectIngest = (state: RootState) => state.ingest;
