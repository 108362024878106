import { ReactNode } from 'react';

export type OrderDeliveryStatus =
  | 'NO_DELIVERY_NEEDED'
  | 'WAITING_FOR_LICENSE'
  | 'IN_PROGRESS'
  | 'SUCCESS'
  | 'FAILED'
  | 'CANCELLED';

export type DerivedOrderDeliveryStatus =
  | OrderDeliveryStatus
  | 'MISSING_CONTENT'
  | 'READY_FOR_QC'
  | 'ACTION_REQUIRED';

export const DerivedOrderDeliveryStatusLabels: Record<DerivedOrderDeliveryStatus, string> = {
  NO_DELIVERY_NEEDED: 'License Only',
  WAITING_FOR_LICENSE: 'Waiting for License',
  IN_PROGRESS: 'In Progress',
  SUCCESS: 'Success',
  FAILED: 'Failed',
  CANCELLED: 'Cancelled',
  MISSING_CONTENT: 'Missing Content',
  READY_FOR_QC: 'Ready for QC',
  ACTION_REQUIRED: 'Action Required'
} as const;

export const DeliveryItemStatusLabels: Record<string, string> = {
  WAITING_FOR_LICENSE: 'Waiting for License',
  CHECKING_CONTENT_AVAILABILITY: 'Checking Content Availability',
  MISSING_CONTENT: 'Missing Content',
  CONTENT_READY: 'Content Ready',
  ENCODE_IN_PROGRESS: 'Encode In Progress',
  ENCODE_SUCCESS: 'Ready for QC',
  ENCODE_FAILED: 'Encode Failed',
  ENCODE_ABORTED: 'Encode Aborted',
  DELIVERY_IN_PROGRESS: 'Delivery in Progress',
  DELIVERY_SUCCESS: 'Delivery Success',
  DELIVERY_FAILED: 'Delivery Failed',
  DELIVERY_ABORTED: 'Delivery Aborted',
  CANCELLED: 'Cancelled'
} as const;
export type DeliveryItemStatus = keyof typeof DeliveryItemStatusLabels;

export const OrderLicenseStatusLabels: Record<string, string> = {
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  PENDING: 'Pending'
} as const;
export type OrderLicenseStatus = keyof typeof OrderLicenseStatusLabels;

export const ReferentTypeLabels: Record<string, string> = {
  SERIES: 'Series',
  SEASON: 'Season',
  TV: 'TV',
  MOVIE: 'Movie',
  WEB: 'Web',
  SHORT: 'Short',
  COMPILATION: 'Compilation',
  SUPPLEMENTAL: 'Supplemental',
  DOCUMENTARY: 'Documentary'
} as const;
export type ReferentType = keyof typeof ReferentTypeLabels;

export const DistributorLabels: Record<string, string> = {
  WARNER_BROS: 'Warner Media',
  HBO: 'HBO',
  DISNEY: 'Disney',
  SONY: 'Sony',
  TURNER: 'Turner',
  UNIVERSAL: 'Universal',
  ADULT_SWIM: 'Adult Swim',
  CARTOON_NETWORK: 'Cartoon Network'
} as const;

export enum RepositoryTypeLabels {
  TV = 'TV',
  MOVIES = 'Movies',
  SHORTS = 'Shorts',
  STANDALONE = 'Standalone'
}

export type Distributor = keyof typeof DistributorLabels;

export const EditVersionLabels: Record<string, string> = {
  TH: 'Theatrical (TH)',
  ED: 'Airline (ED)',
  US: 'US version (US)',
  TR: 'Trailer (TR)'
} as const;
export type EditVersion = keyof typeof EditVersionLabels;

export const FileStatusLabels: Record<string, string> = {
  NOT_STARTED: 'Not started',
  IN_PROGRESS: 'In progress',
  SUCCESS: 'Success',
  FAILED: 'Failed'
} as const;
export type FileStatus = keyof typeof FileStatusLabels;

export interface Meta {
  page: number;
  pageSize: number;
  total: number;
}

export const SourceLabels: Record<string, string> = {
  ABOVE: 'Above',
  WBTVD: 'WarnerBros'
} as const;
export type Source = keyof typeof SourceLabels;

export interface Option {
  value: string;
  label: string | ReactNode;
}

export interface OptionPlain {
  value: string;
  label: string;
}

export const OrderItemTitleTypeLabels: Record<string, string> = {
  EPISODE: 'Episode',
  MOVIE: 'Movie',
  SHORT: 'Short',
  DOCUMENTARY: 'Documentary',
  SPECIAL: 'Special',
  SERIES: 'Series'
} as const;
export type OrderItemTitleType = keyof typeof OrderItemTitleTypeLabels;

export interface OrderTitleSystemInput {
  poDestination: string;
  filename: string;
  editVersion: string;
  audioLanguages: string[];
  subtitleLanguages: string[];
  requireHd: boolean;
  require16x9: boolean;
  ifeSystem: string;
  includeVideo: boolean;
  repositoryItemId: string;
  ifeFormat: string;
  vtkTemplate: string;
  titleCard: string;
}

export interface OrderTitleInput {
  titleType: string;
  name: string;
  seasonNumber: number;
  episodeNumber: number;
  seriesName: string;
  licenseStart: string;
  licenseEnd: string;
  dealName: string;
  titleCatalogId: string;
  titleEidrId: string;
  deliveryFormats: [OrderTitleSystemInput];
}

export interface OrderFormProps {
  organization?: string;
  externalId: string;
  includeVideo?: boolean;
  editVersion: string;
  orderType: string;
  titleCard?: string;
  subtitleLanguages: string[];
  audioLanguages: string[];
  orderGroupId: string;
  titles: OrderTitleInput[];
  sourceCatalog: string;
  dynamicFields: any;
  brand: string;
}

export interface OrderFormPayload {
  organization?: string;
  externalId?: string;
  orderGroupId: string;
  titles: OrderTitleInput[];
  sourceCatalog: string;
  brand: string;
}

export interface Language {
  text: string;
  value: string;
}

export interface AspectRatio {
  text: string;
  value: string;
}

export interface Quality {
  text: string;
  value: string;
}

export interface TargetIFEFormat {
  text: string;
  value: string;
  watermark: boolean;
}

export interface TargetIFE {
  formats: TargetIFEFormat[];
}

export interface HistoryLog {
  user: string;
  userFullName: string | null;
  userOrganization: string | null;
  userOrganizationName: string | null;
  action: string;
  message: string;
  timestamp: string;
}
